import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const TitleSection = styled.div`
  color: ${({ theme: { color } }) => color.secondary.main};
  font-size: ${({ theme: { size } }) => size.fontSize.l};
  text-decoration: underline;
  margin-top: 4rem;

  b {
    font-weight: bold;
  }
`

export const SubTitleSection = styled.div`
  color: ${({ theme: { color } }) => color.secondary.main};
  font-size: ${({ theme: { size } }) => size.fontSize.m};
  margin-bottom: 1rem;
  margin-top: 2rem;
`

export const Table = styled.table`
  width: 270px;
`

export const TBody = styled.tbody``

export const Tr = styled.tr``

export const Td = styled.td`
  white-space: nowrap;
  padding: 10px 15px;
  border: 1px;
  border: ${(
    { theme: { color }, noBorder },
  ) => noBorder ? 'none' : `1px solid  ${color.secondary.main}`
};
`
