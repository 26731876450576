/* eslint-disable max-len */
import React from 'react'
import { number, arrayOf } from 'prop-types'

import * as S from './styles'
import { PRICES } from '../data'

const Grille = () => (
  <S.Wrapper>
    <S.TitleSection>
      <b>Délai standard 3 semaines</b>
      {' '}
      (n’inclut pas la livraison)
    </S.TitleSection>

    <S.SubTitleSection>
      Impression une couleur :
    </S.SubTitleSection>
    <MiniTable classic={PRICES.slow.classic.oneColor} bio={PRICES.slow.bio.oneColor} />

    <S.SubTitleSection>
      Impression deux couleurs :
    </S.SubTitleSection>
    <MiniTable classic={PRICES.slow.classic.twoColors} bio={PRICES.slow.bio.twoColors} />

    <S.SubTitleSection>
      Impression une couleur recto + une couleur verso :
    </S.SubTitleSection>
    <MiniTable classic={PRICES.slow.classic.rectoVerso} bio={PRICES.slow.bio.rectoVerso} />

    <S.TitleSection>
      <b>Délai express 10 jours</b>
      {' '}
      (n’inclut pas la livraison)
    </S.TitleSection>

    <S.SubTitleSection>
      Impression une couleur :
    </S.SubTitleSection>
    <MiniTable classic={PRICES.speed.classic.oneColor} bio={PRICES.speed.bio.oneColor} />

    <S.SubTitleSection>
      Impression deux couleurs :
    </S.SubTitleSection>
    <MiniTable classic={PRICES.speed.classic.twoColors} bio={PRICES.speed.bio.twoColors} />

    <S.SubTitleSection>
      Impression une couleur recto + une couleur verso :
    </S.SubTitleSection>
    <MiniTable classic={PRICES.speed.classic.rectoVerso} bio={PRICES.speed.bio.rectoVerso} />
  </S.Wrapper>
)

const MiniTable = ({ classic, bio }) => {
  const labels = classic.map(({ min, max }) => `${min} à ${max}`)

  return (
    <div>
      <S.Table>
        <S.TBody>
          <S.Tr>
            <S.Td noBorder />
            <S.Td>T-shirts classique</S.Td>
            <S.Td>T-shirts Bio</S.Td>
          </S.Tr>

          {labels.map((q, i) => (
            <S.Tr key={q}>
              <S.Td>{q}</S.Td>
              <S.Td>
                {classic[i].price.unity}
                €
                {classic[i].price.forfait ?
                  ` + ${classic[i].price.forfait}€` : ''}
              </S.Td>
              <S.Td>
                {bio[i].price.unity}
                €
                {bio[i].price.forfait ?
                  ` + ${bio[i].price.forfait}€` : ''}
              </S.Td>
            </S.Tr>
          ))}

          <S.Tr>
            <S.Td>+200</S.Td>
            <S.Td>sur devis</S.Td>
            <S.Td>sur devis</S.Td>
          </S.Tr>
        </S.TBody>
      </S.Table>
    </div>
  )
}

MiniTable.propTypes = {
  classic: arrayOf({ min: number, max: number }).isRequired,
  bio: arrayOf({ min: number, max: number }).isRequired,
}

export default Grille
